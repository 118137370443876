<template lang="pug">
  AppNestedRoutesTabs(:routes="routes")
</template>

<script>
  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    computed: {
      routes() {
        return [
          {
            name: "Shops",
            title: this.$t("company_system.shop_settings.shops.title")
          },
          {
            name: "InventoryGroups",
            title: this.$t("company_system.shop_settings.inventory_groups.title")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
